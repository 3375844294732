/* eslint-disable no-undef */
import { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";

import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setPropertyCardItem,
    setMapViewPortCenterCoords,
    setIframeZoom,
    setPropertyCardClassName,
    setOverwriteCalculatorPopup,
    setCalculatorSlideout,
    setAutoCompleteResult
} from "../../store";
import PropertyService from "../../domain/property/PropertyService";
import PropertyMarkerEntity from "../../domain/map/propertyMarker/PropertyMarkerEntity";
import TypeFloat from "../../domain/valueObject/TypeFloat";
import BubbleEntity from "../../domain/map/bubble/BubbleEntity";

import BlockPill from "./BlockPill";
import BlockBubble from "./BlockBubble";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import BlockPropertyCard from "./propertyCard/BlockPropertyCard";
import IconLocationDotSlash from "../icons/IconLocationDotSlash";
import BlockStreetView from "./BlockStreetView";
import { featureLayerSupported } from "../../utils/uiUitls";
import mapTriggersSliceState, {
    setTriggerDocTab,
    setTriggerNotesTab,
    setTriggerZoomToPolygon
} from "../../store/mapTriggers";
import { useSearchParams } from "react-router-dom";
import URLObjectEntity from "../../domain/urlQuery/URLObjectEntity";
import URLObjectService from "../../domain/urlQuery/URLObjectService";
import TypeString from "../../domain/valueObject/TypeString";
import PropertyEntity from "../../domain/property/PropertyEntity";
import TypeBoolean from "../../domain/valueObject/TypeBoolean";
import responsiveSliceState, { setOverlays } from "../../store/responsive";
import PropertyIdMessage from "../../domain/iFrameMessage/calculator/PropertyIdMessage";
import PropertyAddressMessage from "../../domain/iFrameMessage/calculator/PropertyAddressMessage";
import favoritePropertySliceState from "../../store/favoriteProperty";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import { Popover } from "antd";
import { faHouseBuilding } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let featureLayer: any;

// Stroke and fill with minimum opacity value.
//@ts-ignore
const styleDefault: google.maps.FeatureStyleOptions = {
    strokeColor: "#9c9c9c",
    strokeOpacity: 1,
    strokeWeight: 4,
    fillColor: "transparent",
    fillOpacity: 1 // Polygons must be visible to receive click events.
};

// Style for the clicked Administrative Area Level 2 polygon.
//@ts-ignore
const outlineCSSStyle: google.maps.FeatureStyleOptions = {
    ...styleDefault,
    fillColor: "transparent",
    fillOpacity: 0.5
};

interface BlockMapProps {
    isSidebarCollapsed: boolean;
}

const BlockMap = ({ isSidebarCollapsed }: BlockMapProps) => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [map, setMap] = useState<any>(null);
    const [activeMapType, setActiveMapType] = useState<any>("roadmap");
    const [propertyMarkers, setPropertyMarkers] = useState<any>([]);
    const [streetView, setStreetView] = useState(false);
    const [disableDoubleClickZoom, setDisableDoubleClickZoom] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [shapes, setShapes] = useState<any>([]);
    // const [msOut, setMsOut] = useState<any>();
    // const [apiLoaded, setApiLoaded] = useState<boolean>(false);
    // const [currView, setCurrView] = useState<any>([]);
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const [popoverContent, setPopoverContent] = useState<any>();
    const [mousePosition, setMousePosition] = useState<any>({ x: 0, y: 0 });

    //const [showFavoriteOverlay, setShowFavoriteOverlay] = useState(true);
    const [defaultMapAccess, setDefaultMapAccess] = useState(false); // When the user accesses the map without any input parameters for setting the loction of the map

    const query: PropertySearchQuery = PropertyService.getSearchQuery();
    // eslint-disable-next-line no-unused-vars
    const url: URLObjectEntity = URLObjectService.getURLObject(
        searchParams,
        setSearchParams
    );

    url.setPropertySearchQuery(query);

    const sliceState = useSelector(indexSliceState);
    const mapSliceState = useSelector(mapTriggersSliceState);
    const favoritePropertiesSlice = useSelector(favoritePropertySliceState);
    const dispatch = useDispatch();
    const [mapViewport, setMapViewport] = useState(
        sliceState.mapViewPortCenterCoords
    );

    const zoomLevel = query.zoomLevel.value;
    const propertyId = query.propertyId?.value;

    const responsiveState = useSelector(responsiveSliceState);

    useEffect(() => {
        if (mapSliceState.triggerZoomToPolygon != undefined) {
            zoomToPolygon(mapSliceState.triggerZoomToPolygon);
            dispatch(setTriggerZoomToPolygon(undefined));
        }
    }, [mapSliceState.triggerZoomToPolygon]);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (currentParams.placeId) {
            openPropertyCardFromPlaceId(currentParams.placeId);
        } else if (currentParams.propertyId) {
            openPropertyCardByPropertyId(currentParams);
        } else {
            if (
                !currentParams.lat &&
                !currentParams.lng &&
                !currentParams.propertyId &&
                !currentParams.calculatorShareId &&
                !currentParams.zoomLevel
            ) {
                setDefaultMapAccess(true);
            }
            if (currentParams !== undefined) {
                initializeFromURL(currentParams);
            }
            // if (localStorage.getItem("hideFavoriteOverlay")) {
            //     setShowFavoriteOverlay(false);
            // }
        }
    }, [sliceState.isLoggedIn, favoritePropertiesSlice.favoritesProperties]);

    // Extract google's API response address_components by specific keys
    const getCountryCodeComponent = (address_components: any, type: string) => {
        let value = "";
        address_components.forEach((comp: any) => {
            if (comp.types.includes(type)) {
                value = comp.short_name;
            }
        });
        return value;
    };
    const getUrlParameters = (result: any) => {
        const street =
            getCountryCodeComponent(result, "street_number") +
            " " +
            getCountryCodeComponent(result, "route");

        const state = getCountryCodeComponent(
            result,
            "administrative_area_level_1"
        );
        const county = getCountryCodeComponent(
            result,
            "administrative_area_level_2"
        );
        const zip = getCountryCodeComponent(result, "postal_code");
        const city = getCountryCodeComponent(result, "sublocality_level_1");

        return {
            street,
            state,
            county,
            zip,
            city
        };
    };

    const openPropertyCardFromPlaceId = async (placeId: string) => {
        geocodeByPlaceId(placeId).then((results) => {
            dispatch(setAutoCompleteResult(results[0]));

            getLatLng(results[0]).then(({ lat, lng }) => {
                //setSearchIsTriggered(true);

                if (
                    results[0].types.includes("street_address") ||
                    results[0].types.includes("premise") ||
                    results[0].types.includes("establishment")
                ) {
                    const params = getUrlParameters(
                        results[0].address_components
                    );
                    dispatch(
                        setOverlays({
                            propertySearch: false
                        })
                    );

                    PropertyService.getPropertyByGeoData(
                        new TypeString(params.street),
                        new TypeString(params.state),
                        new TypeString(params.county),
                        new TypeString(params.zip),
                        new TypeString(
                            params.city.length
                                ? params.city
                                : getCountryCodeComponent(
                                      results[0].address_components,
                                      "locality"
                                  )
                        ),
                        new TypeFloat(lat),
                        new TypeFloat(lng)
                    )
                        .then((res: any) => {
                            dispatch(
                                setPropertyCardItem({
                                    data: res,
                                    isVisible: true,
                                    lat,
                                    lng
                                })
                            );
                            dispatch(setPropertyCardClassName(""));
                            //setSearchIsTriggered(false);
                        })
                        .catch(() => {
                            dispatch(
                                setPropertyCardItem({
                                    data: null,
                                    isVisible: true,
                                    lat,
                                    lng
                                })
                            );
                            dispatch(
                                setPropertyCardClassName("right no-property")
                            );
                            //setSearchIsTriggered(false);
                        });
                }

                if (
                    results[0].formatted_address ===
                    sliceState.autoCompleteResult?.formatted_address
                ) {
                    dispatch(setTriggerZoomToPolygon(results[0].geometry));
                }
                dispatch(
                    setMapViewPortCenterCoords({
                        lat,
                        lng
                    })
                );
            });
        });
    };

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (currentParams.placeId || currentParams.propertyId) return;
        if (
            favoritePropertiesSlice.favoritesProperties?.length > 0 &&
            defaultMapAccess
        ) {
            setDefaultMapAccess(false);
            zoomToFavoriteViewport();
        }
    }, [favoritePropertiesSlice.favoritesProperties, sliceState.isLoggedIn]);

    const openPropertyCardByPropertyId = async (
        currentParams: any,
        propertyId?: string | undefined
    ) => {
        const property = await PropertyService.getPropertyById(
            new TypeString(propertyId ? propertyId : currentParams.propertyId)
        );
        console.log("zoomLevel", query.zoomLevel.value);
        if (query.zoomLevel.value < 15) {
            query.setZoomLevel(new TypeFloat(15)).apply();
        }
        query
            .setMapCenterLatitude(
                new TypeFloat(property.propertyDetail?.latitude.value)
            )
            .setMapCenterLongitude(
                new TypeFloat(property.propertyDetail?.longitude.value)
            )
            .apply();

        fromQueryObjectToMap();
        openPropertyCard(property.propertyDetail);
    };

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (
            currentParams !== undefined &&
            propertyId &&
            propertyId !== "undefined" // ToDo: check if this case can really happen. Is the value comming from an outside source? I miss value objects.. (Sasa|07/2023)
        ) {
            openPropertyCardByPropertyId(currentParams, propertyId);
        }
    }, [propertyId, sliceState.isLoggedIn]);

    const initializeFromURL = async (currentParams: any) => {
        if (
            currentParams.propertyId &&
            currentParams.propertyId !== "undefined" // ToDo: check if this case can really happen. Is the value comming from an outside source? I miss value objects.. (Sasa|07/2023)
        ) {
            openPropertyCardByPropertyId(currentParams);
            if (currentParams.calculatorShareId) {
                dispatch(
                    setOverwriteCalculatorPopup({
                        isOpen: true,
                        calculatorShareId: currentParams.calculatorShareId
                    })
                );
            }
        } else {
            fromQueryObjectToMap();
        }
    };

    const openPropertyCard = async (property: PropertyEntity) => {
        setTimeout(() => {
            dispatch(
                setPropertyCardItem({
                    data: property,
                    isVisible: true,
                    lat: property.latitude.value,
                    lng: property.longitude.value
                })
            );
            dispatch(setPropertyCardClassName(""));
        }, 2500);
    };

    const openPropertyCardViaShape = async (property: PropertyMarkerEntity) => {
        dispatch(setPropertyCardClassName(""));
        setTimeout(() => {
            dispatch(
                setPropertyCardItem({
                    data: property,
                    isVisible: true,
                    address: property?.address?.value,
                    property_type: property?.propertyType?.value
                })
            );
        }, 500);
    };

    const fromQueryObjectToMap = () => {
        if (query.hasMapCenterLatitude() && query.hasMapCenterLongitude()) {
            dispatch(
                setMapViewPortCenterCoords({
                    lat: query.mapCenterLatitude.value,
                    lng: query.mapCenterLongitude.value
                })
            );
            setMapViewport({
                lat: query.mapCenterLatitude.value,
                lng: query.mapCenterLongitude.value
            });
        }
    };

    useEffect(() => {
        if (mapSliceState.triggerZoomToPolygon != undefined) {
            zoomToPolygon(mapSliceState.triggerZoomToPolygon);
            dispatch(setTriggerZoomToPolygon(undefined));
        }
    }, [mapSliceState.triggerZoomToPolygon, sliceState.isLoggedIn]);

    // Re-run the search when Switch is toggled
    useEffect(() => {
        query
            .setShowOnlyFavoriteProperties(
                new TypeBoolean(mapSliceState.showOnlyFavoriteProperties)
            )
            .apply();
    }, [mapSliceState.showOnlyFavoriteProperties, sliceState.isLoggedIn]);

    useEffect(() => {
        query.afterChange("updating_search_query_items", () => {
            // Move enitity types 1 (pills) to the bottom of array
            shapes.forEach((shape: any) => {
                shape.path.setMap(null);
            });
            shapes.length = 0;

            const sorted = query.items
                .toArray()
                .sort((el) => (el?.entityType?.value === 1 ? 1 : -1));
            sorted.forEach((item: any) => {
                if (item instanceof PropertyMarkerEntity) {
                    if (item.boundaryPoints !== undefined) {
                        drawPolygon(item);
                    }
                }
            });
            setPropertyMarkers(sorted);

            const propCardId = sliceState.propertyCardItem?.data?.id?.value;
            let shouldClosePropCard = false;
            if (propCardId && !(sorted[0] instanceof BubbleEntity)) {
                shouldClosePropCard =
                    sorted.length > 0
                        ? sorted.filter(
                              (item: any) => item.id?.value === propCardId
                          ).length === 0
                        : true;
            }
            if (
                (sorted.length && sorted[0] instanceof BubbleEntity) ||
                shouldClosePropCard
            ) {
                // Close property card if the map is zoomed out to bubbles or
                // its property id is not contained in SORTED array
                dispatch(
                    setPropertyCardItem({
                        data: null,
                        isVisible: false,
                        lat: undefined,
                        lng: undefined
                    })
                );
            }
        });
    }, [sliceState.propertyCardItem, sliceState.isLoggedIn]);

    useEffect(() => {
        if (sliceState.autoCompleteResult) {
            if (featureLayer) featureLayer.style = null;
            zoomToPolygon(sliceState.autoCompleteResult.geometry);
            const featureType =
                sliceState.autoCompleteResult.types[0].toUpperCase();
            if (featureLayerSupported(featureType)) {
                featureLayer = map.getFeatureLayer(
                    sliceState.autoCompleteResult.types[0].toUpperCase()
                );
                applyOutlineToPolygon(sliceState.autoCompleteResult.place_id);
            }
        }
    }, [
        sliceState.autoCompleteResult?.place_id,
        featureLayer,
        sliceState.isLoggedIn
    ]);

    useEffect(() => {
        setTimeout(() => {
            // Wait for the sidebar to be collapsed/expanded before triggering map change
            handleMapChange();
        }, 600);
    }, [isSidebarCollapsed, sliceState.isLoggedIn]);

    function handleMapChange(e?: any) {
        if (!map && !e) return;
        let lat1, lat2, lng1, lng2, lat, lng, zoom;

        // Use default map event when possible
        if (map) {
            const bounds = map.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            zoom = map.getZoom();

            lat1 = ne.lat();
            lat2 = sw.lat();
            lng1 = sw.lng();
            lng2 = ne.lng();

            const mapCenter = map.getCenter();
            lat = mapCenter.lat();
            lng = mapCenter.lng();
        }
        // Use event when map is not available
        else if (e) {
            const bounds = e.bounds;
            lat1 = bounds.nw.lat;
            lat2 = bounds.se.lat;
            lng1 = bounds.nw.lng;
            lng2 = bounds.se.lng;
            zoom = e.zoom;

            lat = e.center.lat;
            lng = e.center.lng;
        }

        dispatch(
            setMapViewPortCenterCoords({
                lat,
                lng
            })
        );

        query
            .setMapBottomRightLatitude(new TypeFloat(lat2))
            .setMapBottomRightLongitude(new TypeFloat(lng2))
            .setMapTopLeftLatitude(new TypeFloat(lat1))
            .setMapTopLeftLongitude(new TypeFloat(lng1))
            .setZoomLevel(new TypeFloat(zoom))
            .setMapCenterLatitude(new TypeFloat(lat))
            .setMapCenterLongitude(new TypeFloat(lng))
            .apply();
    }

    // eslint-disable-next-line no-unused-vars
    const handleApiLoaded = (map: any, maps: any) => {
        mapRef.current = map;
        mapsRef.current = maps;
        setMap(mapRef.current);
        // const element = document.createElement("toggle-map-type");
        // map.controls[google.maps.ControlPosition.TOP_RIGHT].push(element);
    };

    // eslint-disable-next-line no-unused-vars
    const createMapOptions = (map: any) => {
        return {
            disableDefaultUI: false,
            zoomControl: true,
            mapId: process.env.REACT_APP_GOOGLE_MAP_ID || "",
            minZoom: 4,
            isFractionalZoomEnabled: false,
            disableDoubleClickZoom,
            streetViewControl: true,
            clickableIcons: false,
            mapTypeControl: false,
            mapTypeId: activeMapType
        };
    };

    // Apply styles to the map.
    function applyOutlineToPolygon(placeid?: any) {
        // Apply styles to the feature layer.
        featureLayer.style = (options: any) => {
            // Style fill and stroke for a polygon.
            if (placeid && options.feature.placeId == placeid) {
                return outlineCSSStyle;
            }
            // Style only the stroke for the entire feature type.
            // return styleDefault;
        };
    }

    const zoomToFavoriteViewport = () => {
        const mapBoundsLiteral = {
            east: null,
            north: null,
            south: null,
            west: null
        };

        favoritePropertiesSlice.favoritesProperties.forEach(
            (favoriteMarker: any) => {
                const { latitude, longitude } = favoriteMarker;

                if (
                    mapBoundsLiteral.north === null ||
                    latitude > mapBoundsLiteral.north
                ) {
                    mapBoundsLiteral.north = latitude;
                }

                if (
                    mapBoundsLiteral.south === null ||
                    latitude < mapBoundsLiteral.south
                ) {
                    mapBoundsLiteral.south = latitude;
                }

                if (
                    mapBoundsLiteral.east === null ||
                    longitude > mapBoundsLiteral.east
                ) {
                    mapBoundsLiteral.east = longitude;
                }

                if (
                    mapBoundsLiteral.west === null ||
                    longitude < mapBoundsLiteral.west
                ) {
                    mapBoundsLiteral.west = longitude;
                }
            }
        );

        if (map) map.fitBounds(mapBoundsLiteral, 30);
    };

    const zoomToPolygon = (geometry: any) => {
        if (geometry && geometry.viewport) {
            if (geometry.location_type === "GEOMETRIC_CENTER") {
                setTimeout(() => {
                    map.setZoom(17);
                }, 0);
            }
            map.fitBounds(geometry.viewport, 30);
        }
    };

    const setMapTypeId = (mapType: string) => {
        map.setMapTypeId(mapType);
        setActiveMapType(mapType);
    };

    //Close property card when user clicks outside the map
    const closePropertyCard = (e: any) => {
        const element = e.event.target;
        const isPropertyCard = element.closest(".property-card");
        const isMarker = element.closest(".map-pill__wrapper");
        if (!isPropertyCard && !isMarker) {
            dispatch(
                setPropertyCardItem({
                    data: null,
                    isVisible: false,
                    lat: undefined,
                    lng: undefined
                })
            );
            dispatch(setTriggerNotesTab(false));
            dispatch(setTriggerDocTab(false));
            searchParams.delete("propertyId");
            setSearchParams(searchParams);
        }
    };

    const zoomToLevel = (lat: number, lng: number) => {
        dispatch(
            setMapViewPortCenterCoords({
                lat,
                lng
            })
        );

        if (zoomLevel > 3 && zoomLevel < 7) {
            query.setZoomLevel(new TypeFloat(7));
        } else if (zoomLevel === 7 || zoomLevel === 8) {
            query.setZoomLevel(new TypeFloat(9));
        } else if (zoomLevel === 9 || zoomLevel === 10) {
            query.setZoomLevel(new TypeFloat(11));
        } else if (zoomLevel > 10) {
            query.setZoomLevel(new TypeFloat(zoomLevel + 1));
        }
    };

    // This function closes the overlay, and is being called from child component BlockFavoriteOverlayCTA.tsx
    // const closeOverlay = () => {
    //     setFavoriteOverlay(false);
    // };

    // useEffect(() => {
    //     if (favoriteProperties.length === 0) {
    //         alert("aa");
    //         setFavoriteOverlay(true);
    //     }
    // }, [favoriteProperties]);

    useEffect(() => {
        if (streetView) {
            let panorama: google.maps.StreetViewPanorama;
            const streetViewService = new google.maps.StreetViewService();

            const latitude = sliceState.propertyCardItem.data?.latitude
                ? sliceState.propertyCardItem.data?.latitude?.value
                : sliceState?.mapViewPortCenterCoords?.lat;

            const longitude = sliceState.propertyCardItem.data?.longitude
                ? sliceState.propertyCardItem.data?.longitude?.value
                : sliceState?.mapViewPortCenterCoords?.lng;

            const panoramaRequestConfig = {
                location: new google.maps.LatLng(latitude, longitude),
                radius: 50,
                source: google.maps.StreetViewSource.OUTDOOR
            };

            streetViewService.getPanorama(
                panoramaRequestConfig,
                (data, status) => {
                    if (status === google.maps.StreetViewStatus.OK) {
                        panorama = new google.maps.StreetViewPanorama(
                            document.getElementById(
                                "street-view"
                            ) as HTMLElement,
                            {
                                pano: data?.location?.pano,
                                pov: { heading: 0, pitch: 10 }
                            }
                        );
                        setTimeout(() => {
                            panorama.setVisible(true);
                            map.setStreetView(panorama);
                        }, 500);
                    } else {
                        setStreetView(false);
                        map.setStreetView(undefined);
                    }
                }
            );
        }
    }, [streetView, sliceState.isLoggedIn]);

    useEffect(() => {
        dispatch(setIframeZoom(zoomLevel));
    }, [zoomLevel, sliceState.isLoggedIn]);

    // if url contains propertyId & calculatorShareId params - open the calculator slideout
    // for that property, behind the Overwrite Calculations popup
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);

        if (
            currentParams?.propertyId &&
            currentParams?.propertyId !== "undefined" &&
            currentParams?.calculatorShareId &&
            currentParams?.calculatorShareId !== "undefined" &&
            sliceState.propertyCardItem.data
        ) {
            new PropertyIdMessage().send(
                sliceState.calculatorIframe,
                new TypeString(currentParams.propertyId)
            );
            {
                sliceState.propertyCardItem.data.address &&
                    new PropertyAddressMessage().send(
                        sliceState.calculatorIframe,
                        new TypeString(
                            sliceState.propertyCardItem.data.address.value
                        )
                    );
            }
            // Opens a slideout
            {
                sliceState.propertyCardItem.data.address &&
                    dispatch(
                        setCalculatorSlideout({
                            isOpen: true,
                            id: +currentParams.propertyId,
                            overlay: false,
                            address:
                                sliceState.propertyCardItem.data.address.value
                        })
                    );
            }
        }
    }, [sliceState.propertyCardItem.data, sliceState.isLoggedIn]);

    const zoomToNeighborhoodView = (data: PropertyMarkerEntity) => {
        query.setPropertyId(new TypeString(data.id.value)).apply();
    };

    const drawPolygon = (marker: any) => {
        if (marker?.boundaryPoints !== undefined) {
            const formatPopoverAddress = (addressString: string) => {
                if (addressString && addressString.length > 0) {
                    const commaIndex = addressString.indexOf(",");
                    if (commaIndex !== -1) {
                        const street = addressString
                            .slice(0, commaIndex)
                            .trim();
                        const cityStateZip = addressString
                            .slice(commaIndex + 1)
                            .trim();
                        return { street, cityStateZip };
                    } else {
                        const street = addressString.trim();
                        return { street, cityStateZip: null };
                    }
                } else {
                    return { street: "", cityStateZip: "" };
                }
            };
            const polyPopup = (event: any, marker: any) => {
                const { street, cityStateZip } = formatPopoverAddress(
                    marker.address?.value
                );
                setPopoverContent(
                    <div className="popover-pill-address">
                        {marker.propertyType && (
                            <div className="popover-pill-address__property-type">
                                <FontAwesomeIcon
                                    className="popover-pill-address__property-type__icon"
                                    icon={faHouseBuilding}
                                />
                                <span className="popover-pill-address__property-type__text"></span>
                                {marker.propertyType.value}
                            </div>
                        )}
                        <span className="popover-pill-address__title">
                            Address
                        </span>
                        <p className="popover-pill-address-text">{street}</p>
                        <p className="popover-pill-address-text">
                            {cityStateZip}
                        </p>
                    </div>
                );
                setPopoverVisible(true);
            };

            const flightPath = new google.maps.Polygon({
                paths: marker.boundaryPoints,
                geodesic: true,
                strokeColor: "#22AA94",
                fillColor: "#22AA94",
                fillOpacity: 0,
                strokeOpacity: 1.0,
                strokeWeight: 2
            });
            // google.maps.event.addListener(
            //     flightPath,
            //     "mousemove",
            //     function (event: any) {
            //         const offset = event?.domEvent;
            //         const posX = Number(offset.clientX);
            //         const posY = Number(offset.clientY);
            //         setTimeout(() => {
            //             setMousePosition({
            //                 x: posX,
            //                 y: posY
            //             });
            //         }, 100);
            //     }
            // );
            google.maps.event.addListener(flightPath, "mouseover", function () {
                //polyPopup(event, marker);
                flightPath.setOptions({
                    fillOpacity: 0.35
                });
            });

            google.maps.event.addListener(flightPath, "mouseout", function () {
                //setPopoverVisible(false);
                //setPopoverContent(null);
                flightPath.setOptions({
                    fillOpacity: 0
                });
            });
            google.maps.event.addListener(flightPath, "click", function () {
                openPropertyCardViaShape(marker);
            });
            flightPath.setMap(mapRef.current);
            const shapesInstance: Array<any> = shapes;
            shapesInstance.push({
                path: flightPath,
                Address: marker.Address,
                State: marker.State,
                Lat: marker.Lat,
                Lng: marker.Lng
            });
            setShapes(shapesInstance);
        }
    };

    return (
        <>
            <div
                className={`map ${
                    sliceState.propertyCardItem.isVisible ? "card-visible" : ""
                }`}
            >
                {streetView ? (
                    <BlockStreetView
                        closeStreetView={() => {
                            setStreetView(false);
                            map.setStreetView(undefined);
                        }}
                    />
                ) : null}
                <div id="toggle-map-type" className="toggle">
                    <span
                        className={activeMapType === "roadmap" ? "active" : ""}
                        onClick={() => setMapTypeId("roadmap")}
                    >
                        Default
                    </span>
                    <span
                        className={
                            activeMapType === "satellite" ? "active" : ""
                        }
                        onClick={() => setMapTypeId("satellite")}
                    >
                        Satellite
                    </span>
                </div>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAPS_KEY || ""
                    }}
                    center={mapViewport}
                    zoom={zoomLevel}
                    options={createMapOptions}
                    onChange={(e: any) => {
                        handleMapChange(e);
                    }}
                    onClick={closePropertyCard}
                    onGoogleApiLoaded={({ map, maps }: any) =>
                        handleApiLoaded(map, maps)
                    }
                    yesIWantToUseGoogleMapApiInternals={true}
                >
                    {propertyMarkers.map((item: any, i: number) => {
                        if (item instanceof PropertyMarkerEntity) {
                            return (
                                <BlockPill
                                    key={item.id.value}
                                    lat={item.latitude.value}
                                    lng={item.longitude.value}
                                    boundaryPoints={item.boundaryPoints}
                                    data={item}
                                    setDisableDoubleClickZoom={
                                        setDisableDoubleClickZoom
                                    }
                                    zoomToNeighborhoodView={
                                        zoomToNeighborhoodView
                                    }
                                    query={query}
                                />
                            );
                        } else if (item instanceof BubbleEntity) {
                            return (
                                <BlockBubble
                                    key={i}
                                    lat={item.latitude.value}
                                    lng={item.longitude.value}
                                    data={item}
                                    setDisableDoubleClickZoom={
                                        setDisableDoubleClickZoom
                                    }
                                    handleOnClick={() => {
                                        setMapViewport({
                                            lat: item.latitude.value,
                                            lng: item.longitude.value
                                        });
                                        zoomToLevel(
                                            item.latitude.value,
                                            item.longitude.value
                                        );
                                    }}
                                />
                            );
                        }
                    })}
                    {sliceState.propertyCardItem.isVisible &&
                    sliceState.propertyCardItem.data == null ? (
                        <IconLocationDotSlash
                            lat={sliceState.mapViewPortCenterCoords?.lat}
                            lng={sliceState.mapViewPortCenterCoords?.lng}
                        />
                    ) : null}
                    {!responsiveState.isMobile &&
                    sliceState.propertyCardItem.isVisible &&
                    sliceState.propertyCardClassName.length ? (
                        <BlockPropertyCard
                            className="desktop"
                            key="blockPropertyCard"
                            lat={
                                sliceState.propertyCardItem.lat ??
                                sliceState.propertyCardItem.data?.latitude
                                    ?.value
                            }
                            lng={
                                sliceState.propertyCardItem.lng ??
                                sliceState.propertyCardItem.data?.longitude
                                    ?.value
                            }
                            openStreetView={() => setStreetView(true)}
                            id={
                                sliceState.propertyCardItem.data?._id?._value ??
                                sliceState.propertyCardItem.data?.id
                            }
                            setDisableDoubleClickZoom={
                                setDisableDoubleClickZoom
                            }
                            zoom={map ? map.getZoom() : 16}
                        />
                    ) : null}
                </GoogleMapReact>
                <Popover
                    content={
                        <div className="popover-pill-wrapper">
                            {popoverContent}
                        </div>
                    }
                    overlayStyle={{
                        position: "absolute",
                        left: `${mousePosition.x}px`,
                        top: `${mousePosition.y}px`,
                        paddingBottom: "10px",
                        pointerEvents: "none",
                        minWidth: "177px"
                    }}
                    placement="rightTop"
                    trigger="hover"
                    visible={popoverVisible}
                ></Popover>
                {/* PROPERTY CARD FOR MOBILE (must be outside of GoogleMapReact component ) */}
                {responsiveState.isMobile &&
                sliceState.propertyCardItem.isVisible &&
                sliceState.propertyCardClassName.length ? (
                    <BlockPropertyCard
                        className="mobile"
                        key="blockPropertyCard"
                        lat={
                            sliceState.propertyCardItem.lat ??
                            sliceState.propertyCardItem.data?.latitude?.value
                        }
                        lng={
                            sliceState.propertyCardItem.lng ??
                            sliceState.propertyCardItem.data?.longitude?.value
                        }
                        openStreetView={() => setStreetView(true)}
                        id={
                            sliceState.propertyCardItem.data?._id?._value ??
                            sliceState.propertyCardItem.data?.id
                        }
                        setDisableDoubleClickZoom={setDisableDoubleClickZoom}
                        zoom={map ? map.getZoom() : 16}
                    />
                ) : null}
            </div>
        </>
    );
};

export default BlockMap;
