import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setIframe,
    setLenderFlyout,
    setLenderIframe
} from "../../../../store";
import { useAuth0 } from "@auth0/auth0-react";

// Domain imports
import LenderService from "../../../../domain/lender/LenderService";

import { hotjar } from "react-hotjar";
import TypePositiveInteger from "../../../../domain/valueObject/TypePositiveInteger";
import IFrameMessageFactory from "../../../../domain/iFrameMessage/IFrameMessageFactory";
import OpenCTAMessage from "../../../../domain/iFrameMessage/findLender/OpenCTAMessage";
import { WHERE_FROM_CTA } from "../../../../utils/constants";
import OpenFlyoutMessage from "../../../../domain/iFrameMessage/findLender/OpenFlyoutMessage";
import OpenCTAFinancingMessage from "../../../../domain/iFrameMessage/findLender/OpenCTAFinancingMessage";
import FindLenderReadyMessage from "../../../../domain/iFrameMessage/findLender/FindLenderReadyMessage";
import Collection from "../../../../domain/valueObject/Collection";
import SearchLendersMessage from "../../../../domain/iFrameMessage/findLender/SearchLendersMessage";
import responsiveSliceState, {
    setCurrentView,
    setOverlays,
    setShowMapBtn
} from "../../../../store/responsive";
import PartButton from "../../../parts/PartButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import BecomeMemberMessage from "../../../../domain/iFrameMessage/findLender/BecomeMemberMessage";
import LenderCreditsMessage from "../../../../domain/iFrameMessage/findLender/LenderCreditsMessage";
// import BlockFindALenderAccess from "./BlockFindALenderAccess";

const BlockFindALender = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sliceState = useSelector(indexSliceState);
    const dispatch = useDispatch();
    const responsiveState = useSelector(responsiveSliceState);
    const iframeRef = useRef<any>(null);
    const [loggedIn, setLoggedIn] = React.useState(sliceState.isLoggedIn);
    const { loginWithRedirect } = useAuth0();

    // const [blockAccess, setBlockAccess] = useState(false);

    const handlePaidUserClick = () => {
        hotjar.event(`Find a Lender Results - Finance Property`);
        if (sliceState.findALenderData.lastPropertyCardClicked?.propertyId) {
            let splitAddress: any;
            let streetLine: any;
            let cityLine: any;
            if (sliceState.findALenderData.lastPropertyCardClicked?.address) {
                splitAddress =
                    sliceState.findALenderData.lastPropertyCardClicked?.address.split(
                        ","
                    );
            } else if (sliceState.autoCompleteResult?.formatted_address) {
                splitAddress =
                    sliceState.autoCompleteResult?.formatted_address.split(",");
            }
            if (splitAddress.length === 3) {
                streetLine = splitAddress[0];
                cityLine = `${splitAddress[1]}, ${splitAddress[2]}`;
            }

            if (splitAddress.length === 4) {
                streetLine = `${splitAddress[0]}`;
                cityLine = `${splitAddress[1]}, ${splitAddress[2]}, ${splitAddress[3]}`;
            } else if (splitAddress.length === 5) {
                streetLine = `${splitAddress[0]}`;
                cityLine = `${splitAddress[1]}, ${splitAddress[2]}, ${splitAddress[3]}, ${splitAddress[4]}`;
            } else {
                streetLine = splitAddress[0];
                cityLine = `${splitAddress[1]}, ${splitAddress[2]}`;
            }

            dispatch(
                setIframe({
                    show: true,
                    street: streetLine || "",
                    city: cityLine || "",
                    propId: sliceState.findALenderData.lastPropertyCardClicked
                        ?.propertyId,
                    mapLat: sliceState.propertyCardItem?.lat,
                    mapLng: sliceState.propertyCardItem?.lng,
                    mapZoom: sliceState.iframe.zoom,
                    type: "finance",
                    authUser: "true",
                    whereFrom: WHERE_FROM_CTA.LEFT_NAV_LENDER_RESULTS
                })
            );
        } else {
            dispatch(
                setIframe({
                    type: "get-financing",
                    whereFrom: WHERE_FROM_CTA.LEFT_NAV_LENDER_RESULTS,
                    getFinancingType: true
                })
            );
        }
    };

    useEffect(() => {
        // recieve message from iframe
        dispatch(setLenderIframe(iframeRef.current));
        window.addEventListener("message", (event) => {
            const message = IFrameMessageFactory.reconstitute(event.data);
            switch (true) {
                case message instanceof OpenCTAMessage:
                    message!.recieve(handlePaidUserClick);
                    break;
                case message instanceof OpenFlyoutMessage:
                    message!.recieve(() => {
                        dispatch(
                            setLenderFlyout({
                                isOpen: true,
                                data: event.data.data,
                                type: "originator-profiles"
                            })
                        );
                    });
                    break;
                case message instanceof FindLenderReadyMessage:
                    message!.recieve(() => {
                        const currentParams = Object.fromEntries([
                            ...searchParams
                        ]);

                        if (sliceState.lenderIframe) {
                            new FindLenderReadyMessage().send(
                                new Collection({
                                    ...currentParams
                                }),
                                sliceState.lenderIframe
                            );
                        }
                    });
                    break;
                case message instanceof OpenCTAFinancingMessage:
                    message!.recieve(handlePaidUserClick);
                    break;
                case message instanceof SearchLendersMessage:
                    message!.recieve(() => {
                        const lenderQuery =
                            LenderService.getLenderFilterQuery();
                        lenderQuery.setState(
                            new TypePositiveInteger(event.data.state)
                        );

                        searchParams.set("flState", event.data.state);
                        searchParams.set(
                            "flPropertyType",
                            event.data.propertyType
                        );
                        searchParams.set("flLoanType", event.data.loanType);
                        searchParams.set("flLoanAmount", event.data.loanAmount);
                        searchParams.set(
                            "flExecTypes",
                            event.data.executionTypes.length > 0
                                ? event.data.executionTypes
                                : 0
                        );

                        searchParams.set(
                            "flPublicLenders",
                            event.data.publicLenders
                        );

                        setSearchParams(searchParams);
                    });
                    break;
                case message instanceof BecomeMemberMessage:
                    message!.recieve(() => {
                        dispatch(
                            setIframe({
                                show: true,
                                street: "",
                                city: "",
                                propId: "",
                                mapLat: "",
                                mapLng: "",
                                mapZoom: "",
                                type: "join",
                                authUser: "true",
                                whereFrom:
                                    WHERE_FROM_CTA.LEFT_NAV_LENDER_UNLOCK_AMOUNT_AND_LOAN_TYPE
                            })
                        );
                    });
                    break;
                case message instanceof LenderCreditsMessage:
                    console.log(event);
                    message!.recieve(() => {
                        dispatch(
                            setIframe({
                                show: true,
                                street: "",
                                city: "",
                                propId: "",
                                mapLat: "",
                                mapLng: "",
                                mapZoom: "",
                                type: "lenderCredits",
                                authUser: "true",
                                whereFrom: event.data.data.whereFrom
                            })
                        );
                    });
                    break;

                default:
                    break;
            }
        });
    }, [sliceState.lenderIframe]);

    // useEffect(() => {
    //     const isPaidUser = sliceState.isPaidUser;
    //     setBlockAccess(
    //         !isPaidUser &&
    //             (!sliceState.findALenderData.propertyId ||
    //                 sliceState.findALenderData.accessedFrom === "sidebar")
    //     );
    // }, [sliceState.findALenderData]);
    useEffect(() => {
        if (sliceState.isLoggedIn) {
            setLoggedIn(true);
        }
    }, [sliceState.isLoggedIn]);
    const iframeHeight = loggedIn ? "680px" : "214px";

    return (
        <div
            className={`lender ${
                responsiveState.overlays.lenderDefault ? "open" : "closed"
            }`}
        >
            <div className="lender__top-wrap">
                <PartButton
                    type="secondary"
                    onClick={() => {
                        dispatch(setCurrentView("dashboard"));
                        dispatch(
                            setOverlays({
                                ...responsiveState.overlays,
                                lenderDefault: false
                            })
                        );
                        dispatch(setShowMapBtn(true));
                    }}
                >
                    <>
                        <FontAwesomeIcon icon={faArrowLeft} /> tools
                    </>
                </PartButton>
                <h3>FIND A LENDER</h3>
            </div>
            {sliceState.isLoggedIn ? (
                <iframe
                    ref={iframeRef}
                    width={"100%"}
                    height={iframeHeight}
                    src={`${process.env.REACT_APP_FIND_LENDER_IFRAME_URL}${
                        loggedIn ? "&loggedIn=1" : ""
                    }`}
                    title="Find a Lender Iframe"
                    className="find-a-lender-iframe"
                />
            ) : (
                <div className="my-properties__no-data-wrapper">
                    <h1 className="my-properties__no-data-title">
                        Free Signup or Login
                    </h1>
                    <span className="my-properties__no-data-subtitle">
                        <button
                            className="my-properties__results-info-text-btn"
                            onClick={() => {
                                loginWithRedirect({
                                    redirectUri: window.location.href
                                });
                            }}
                        >
                            Sign Up
                        </button>{" "}
                        or{" "}
                        <button
                            className="my-properties__results-info-text-btn"
                            onClick={() => {
                                loginWithRedirect({
                                    redirectUri: window.location.href
                                });
                            }}
                        >
                            Log In
                        </button>{" "}
                        to see properties in your <br /> pipeline and use
                        marketplace tools.
                    </span>
                </div>
            )}
            {/* <BlockFindALenderAccess show={blockAccess} /> */}
        </div>
    );
};

export default BlockFindALender;
